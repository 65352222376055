/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import TitleOfBlock from "./TitleOfBlock";

export default function Video() {
  return (
    <>
      <div className="blocks" id="video">
        <TitleOfBlock title="Опыт АА"/>
        <div className="pb-20 lg:px-32 xl:px-48">
          <div className="relative pb-[56.25%] overflow-hidden">
            <iframe className="absolute top-0 left-0 w-[100%] h-[100%] rounded-xl"
              src="https://vk.com/video_ext.php?oid=-219983824&id=456239021&hd=2"
              width="560"
              height="315"
              allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
              frameborder="0"
              allowfullscreen
            >
            </iframe>
          </div>
        </div>
      </div>
    </>
  );
}